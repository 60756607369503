import React from 'react';
import { AppBar, Container, Toolbar, Grid, Divider, IconButton, Box } from '@material-ui/core';
import { Link } from 'react-router-dom'
// npm install --save-dev @iconify/react @iconify-icons/mdi
import { Icon, InlineIcon } from '@iconify/react';
import soundcloudIcon from '@iconify-icons/mdi/soundcloud';
import facebookIcon from '@iconify-icons/mdi/facebook';
import instagramIcon from '@iconify-icons/mdi/instagram';
import { Logo } from 'assets/images';

import * as PropTypes from 'prop-types';

import styles from './styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = React.forwardRef((props, ref) => {
  const classes = styles();

  return (
    <ElevationScroll {...props}>
      <AppBar ref={ref}>
        <Toolbar>
          <Container maxWidth={'lg'} style={{ padding: 0 }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={2}></Grid>
              <Grid item style={{ textAlign: 'center' }}>
                <Grid
                  container
                  spacing={8}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Link to={'/'} className={classes.link}>Home</Link>
                  </Grid>
                  <Grid item>
                    <Link to={'/events'} className={classes.link}>Events</Link>
                  </Grid>
                  <Grid item>
                    <img src={Logo} style={{ width: 120, height: 120 }} />
                  </Grid>
                  <Grid item>
                    <Link to={'/contact'} className={classes.link}>Contact</Link>
                  </Grid>
                  <Grid item>
                    <Link to={'/about'} className={classes.link}>About</Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2} style={{ textAlign: 'right' }}>
                <IconButton aria-label="SoundCloud" color="secondary">
                  <Icon icon={soundcloudIcon} />
                </IconButton>
                <IconButton aria-label="Facebook" color="secondary">
                  <Icon icon={facebookIcon} />
                </IconButton>
                <IconButton aria-label="Instagram" color="secondary">
                  <Icon icon={instagramIcon} />
                </IconButton>
              </Grid>
            </Grid>


          </Container>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
});

export default Header;
