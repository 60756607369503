import firebase from 'firebase';
import 'firebase/database';

firebase.initializeApp({
  apiKey: 'AIzaSyDADCSgZeO1RZphd_atjtsM04oiQCMwZiY',
  authDomain: 'schmaushouse-c92d9.firebaseapp.com',
  databaseURL: 'https://schmaushouse-c92d9.firebaseio.com',
  projectId: 'schmaushouse-c92d9',
  storageBucket: 'schmaushouse-c92d9.appspot.com',
  messagingSenderId: '931624891742',
  appId: '1:931624891742:web:30d3a462c9a88b1457cbcf',
  measurementId: 'G-WP0ZQEGKYE'
});

export const database = firebase.database();
