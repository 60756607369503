import React, { createContext, useReducer, useRef } from 'react';
import ReactPlayer from 'react-player/soundcloud';

const initialState = {
  duration: 0,
  currentTime: 0,
  playedProcent: 0,
  playState: false,
  volume: 0.8,
  currentTrack: 0,
  currentPlaylist: 0,
  repeat: false,
};


const playerContext = createContext(initialState);
const { Provider } = playerContext;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_TIME':
      return { ...state, currentTime: action.payload };
    case 'SET_PLAYSTATE':
      return { ...state, playState: action.payload };
    case 'SET_DURATION':
      return { ...state, duration: action.payload };
    case 'SET_PLAYED_PROCENT':
      return { ...state, playedProcent: action.payload };
    case 'SET_VOLUME':
      return { ...state, volume: action.payload };
    case 'SET_CURRENT_TRACK':
      return { ...state, currentTrack: action.payload };
    case 'SET_CURRENT_PLAYLIST':
      return { ...state, currentPlaylist: action.payload };
    case 'SET_REPEAT':
      return { ...state, repeat: action.payload };
    default:
      return state;
  }
};

const PlayerContextProvider = ({ children, playlists }) => {
  const [state, setPlayerState] = useReducer(reducer, initialState);

  const playerRef = useRef();

  if (!playlists.length) return null;

  return (
    <>
      <ReactPlayer
        url={playlists[state.currentPlaylist].tracks[state.currentTrack].url}
        onProgress={params => {
          setPlayerState({ type: 'SET_CURRENT_TIME', payload: Math.round(params.playedSeconds) })
          setPlayerState({ type: 'SET_PLAYED_PROCENT', payload: params.played.toFixed(4) * 100 })
        }}
        onDuration={seconds => setPlayerState({ type: 'SET_DURATION', payload: seconds })}
        playing={state.playState}
        ref={playerRef}
        volume={state.volume}
        style={{
          bottom: -600,
          width: '10px',
          position: 'fixed'
        }}
      />
      <Provider value={{ state, setPlayerState, playerRef }}>
        {children}
      </Provider>
    </>
  );
};

export { playerContext, PlayerContextProvider }
