import React, { useContext, useRef, useState } from 'react';

import styles from './styles';
import { PlaylistPlay, Repeat } from '@material-ui/icons';
import { IconButton, Paper, Popper } from '@material-ui/core';
import PlaylistComponent from 'components/Elements/PlaylistComponent';
import { playerContext } from 'context/playerContext';

const PlayerPlaylist = () => {
  const classes = styles();
  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const { state: { repeat }, setPlayerState } = useContext(playerContext);

  const handleToggleRepeat = () => setPlayerState({ type: 'SET_REPEAT', payload: !repeat });

  return (
    <>
      <IconButton style={{ padding: 0 }} onClick={handleToggleRepeat} className={repeat ? classes.active : ''}>
        <Repeat style={{ width: 20, height: 20 }} />
      </IconButton>
      <IconButton ref={anchorEl} onClick={() => setOpen(prevState => !prevState)}>
        <PlaylistPlay />
      </IconButton>

      <Popper id="playlistPopper" open={open} anchorEl={anchorEl.current} placement="top-end" style={{ zIndex: 100 }}>
        <Paper elevation={2} style={{
          padding: '2px 2px 8px 0',
          width: 420,
          height: 200,
          borderRadius: 0,
          borderBottom: 0,
          marginBottom: 0
        }}>
          <PlaylistComponent />
        </Paper>
      </Popper>
    </>
  );
};

export default PlayerPlaylist;
