import React, { useContext } from 'react';

import styles from './styles';
import { Grid, IconButton } from '@material-ui/core';
import { Pause, PlayArrow, SkipNext, SkipPrevious } from '@material-ui/icons';
import { playerContext } from 'context/playerContext';
import { store } from 'store';

const PlayerButtons = () => {
  const classes = styles();

  const { state: { playlists } } = useContext(store);
  const { state: { playState, currentTrack, currentPlaylist, repeat }, setPlayerState } = useContext(playerContext);

  const handlePlay = () => setPlayerState({ type: 'SET_PLAYSTATE', payload: !playState })
  const handlePrev = () => setPlayerState({ type: 'SET_CURRENT_TRACK', payload: currentTrack === 0 ? pMax : currentTrack - 1 });
  const handleNext = () => setPlayerState({ type: 'SET_CURRENT_TRACK', payload: currentTrack === pMax ? 0 : currentTrack + 1 });;

  const pMax = playlists[currentPlaylist].tracks.length - 1;

  return (
    <>
      <IconButton
        aria-label="previous"
        disabled={!repeat && currentTrack === 0}
        onClick={handlePrev}
      >
        <SkipPrevious />
      </IconButton>
      <IconButton aria-label="play" onClick={handlePlay}>
        {playState ? <Pause /> : <PlayArrow />}
      </IconButton>
      <IconButton
        aria-label="next"
        disabled={!repeat && currentTrack === pMax}
        onClick={handleNext}
      >
        <SkipNext />
      </IconButton>
    </>
  );
};

export default PlayerButtons;
