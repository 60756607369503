import { makeStyles } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

const styles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(2),
  },
  active: {
    color: orange[600]
  }
}));

export default styles;
