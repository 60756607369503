import React, { useContext } from 'react';

import styles from './styles';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { playerContext } from 'context/playerContext';
import { store } from 'store';

const columns = [
  { id: 'track', label: 'Track', minWidth: 170, align: 'left' },
  { id: 'artist', label: 'Artist', minWidth: 100, align: 'right' },
];

const PlaylistComponent = () => {
  const classes = styles();

  const { state: { currentTrack, currentPlaylist } } = useContext(playerContext);
  const { state: { playlists } } = useContext(store);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {playlists[currentPlaylist].tracks.map((row, i) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.track}>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} className={classes.column}>
                  {row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlaylistComponent;
