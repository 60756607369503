import loadable from '@loadable/component';

const exact = true;
const withLoadable = Component => loadable(() => Component, { fallback: <div /> });

const routes = [
  {
    path: '/',
    exact,
    name: 'Home',
    component: withLoadable(import('components/Pages/Home')),
  },
  {
    path: '/about',
    exact,
    name: 'About',
    component: withLoadable(import('components/Pages/About')),
  },
  {
    path: '/contact',
    exact,
    name: 'Contact',
    component: withLoadable(import('components/Pages/Contact')),
  },
  {
    path: '/events',
    exact,
    name: 'Events',
    component: withLoadable(import('components/Pages/Events')),
  },
];

export default routes;
