import React, { useEffect, useContext, useMemo } from 'react';
import Layout from 'components/Containers/Default';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase';
import { Button } from '@material-ui/core';
import { store } from 'store';

function App() {
  console.count('Render')

  return (
    <Router>
      <CssBaseline />
      <Layout />
    </Router>
  );
}

export default App;
