import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(2),
  },
  bgSecondary: {
    backgroundColor: theme.palette.secondary.main,
  }
}));

export default styles;
