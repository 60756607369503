import React, { useContext, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

import styles from './styles';
import { Grid, IconButton, Paper, Popper, Slider } from '@material-ui/core';
import { VolumeUp, VolumeDown, VolumeOff } from '@material-ui/icons';
import { playerContext } from 'context/playerContext';


const PlayerVolume = () => {
  const classes = styles();
  const anchorEl = useRef();

  const [open, setOpen] = useState(false);
  const { state: { volume }, setPlayerState } = useContext(playerContext);

  return (
    <>
      <IconButton aria-label="Volume" ref={anchorEl} onClick={() => setOpen(prevState => !prevState)}>
        {volume ? (volume > 0.6 ? <VolumeUp /> : <VolumeDown />) : <VolumeOff />}
      </IconButton>
      <Popper id="volumePopper" open={open} anchorEl={anchorEl.current} placement="top" style={{ zIndex: 2000}}>
        <Paper elevation={2} style={{ padding: '8px 2px' }}>
          <Slider
            orientation="vertical"
            value={volume * 100}
            style={{ height: 100 }}
            onChange={(e, v) => {
              setPlayerState({ type: 'SET_VOLUME', payload: v / 100 });
            }}
          />
        </Paper>
      </Popper>
    </>
  );
};

export default PlayerVolume;
