import React, { useContext } from 'react';
import Header from './Header';
import { Box, Container, Toolbar } from '@material-ui/core';
import ScrollTop from './ScrollTop';
import styles from './styles';
import Router from './Router';
import Player from 'components/Containers/Default/Player';
import { PlayerContextProvider } from 'context/playerContext';
import { store } from 'store';


const Layout = () => {
  const classes = styles();
  const toolbarRef = React.createRef();
  const { state: { playlists }, loading } = useContext(store);

  return (
    <>
      <Header ref={toolbarRef} />
      {
        loading
          ? null
          :
          <PlayerContextProvider playlists={playlists}>
            <Container
              maxWidth="lg"
              style={{
                marginTop: 130,
                marginBottom: 48,
                minHeight: '84vh',
                paddingTop: 16,
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              <Router />
            </Container>
            <Player />
            <ScrollTop ref={toolbarRef} />
          </PlayerContextProvider>
      }
    </>
  );
};

export default Layout;
