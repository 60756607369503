import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  tableContainer: {
    height: '100%',
    paddingRight: theme.spacing(2),
  },
  column: {
    fontSize: 12,
    userSelect: 'none'
  }
}));

export default styles;
