import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';

import styles from './styles';
import { Grid, Typography } from '@material-ui/core';
import { playerContext } from 'context/playerContext';
import { store } from 'store';

const PlayerTrack = () => {
  const classes = styles();

  const { state: { currentTrack, currentPlaylist } } = useContext(playerContext);
  const { state: { playlists } } = useContext(store);

  const track = playlists[currentPlaylist].tracks[currentTrack];

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <img src={track.image} style={{ width: 36, height: 36 }} alt="artwork"/>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          {track.artist}
        </Typography>
        <Typography variant="subtitle2">
          {track.track}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PlayerTrack;
