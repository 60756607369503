import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}));

export default styles;
