import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(2),
  },
  link: {
    fontSize: 24,
    color: theme.palette.secondary.main,
    fontWeight: 700,
    textDecoration: 'none',
  }
}));

export default styles;
