import { createMuiTheme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    fontFamily: 'JosefinSans'
  },
  palette: {
    primary: { main: '#fff' },
    //secondary: { main: '#000' },
    secondary: { main: '#131318' },
  },
  overrides: {
    MuiSlider: {
      root: {
        color: orange[600],
      },
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1280,
      xl: 1920,
    },
  }
});

export default theme;
