import React from 'react';
import { AppBar, Container, Grid, Toolbar } from '@material-ui/core';
import styles from './styles';
import PlayerButtons from './PlayerButtons';
import PlayerSeek from './PlayerSeek';
import PlayerVolume from './PlayerVolume';
import PlayerPlaylist from './PlayerPlaylist';
import PlayerTrack from './PlayerTrack';

const Player = () => {
  const classes = styles();

  return (
    <AppBar position="static" style={{ bottom: 0, left: 0, width: '100%', position: 'fixed' }}>
      <Toolbar variant="dense">
        <Container maxWidth={'lg'} style={{ padding: 0 }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <PlayerButtons />
            </Grid>

            <Grid item md={6}>
              <PlayerSeek />
            </Grid>

            <Grid item>
              <PlayerVolume />
            </Grid>

            <Grid item>
              <PlayerTrack />
            </Grid>

            <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
              <PlayerPlaylist />
            </Grid>

          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Player;
