import React, { useContext } from 'react';

import styles from './styles';
import { Grid, Slider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { playerContext } from 'context/playerContext';

const digits = s => `${(String(s).length === 1 ? '0' : '')}${s}`;
const getTime = s => `${Math.floor(s / 3600)}:${digits(Math.floor((s % 3600) / 60))}:${digits(Math.floor(s % 60))}`;

const SeekSlider = withStyles({
  thumb: {
    height: 4,
    width: 4,
    //marginTop: -14,
    //marginLeft: -14,
    marginTop: -1,
    marginLeft: 0,
    '&:focus, &:hover, &$active': {
      marginTop: -6,
      marginLeft: -5,
      height: 14,
      width: 14,
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {},
    },
  },
})(Slider);

const PlayerSeek = () => {
  const classes = styles();
  const { state: { currentTime, duration, playState, playedProcent }, setPlayerState, playerRef } = useContext(playerContext);

  const handleChange = (event, newValue) => {
    setPlayerState({ type: 'SET_CURRENT_TIME', payload: Math.round(newValue * duration / 100) })
    playerRef.current.seekTo(newValue / 100, 'fraction');
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="subtitle2"
          style={{ minWidth: 50, textAlign: 'center' }}
        >
          {getTime(currentTime)}
        </Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <SeekSlider
          value={playedProcent}
          onChange={handleChange}
          aria-labelledby="continuous-slider"
          step={0.1}
          scale={(x) => x ** 10}
          disabled={!playState}
          style={{ marginTop: 6 }}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ minWidth: 50, textAlign: 'center' }}>{getTime(duration)}</Typography>
      </Grid>
    </Grid>
  );
};

export default PlayerSeek;
