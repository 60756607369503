import React, { createContext, useEffect, useReducer } from 'react';
import { useListVals, useObjectVal, useObject } from 'react-firebase-hooks/database';
import { database } from 'firebase/firebaseConfig';

const initialState = {
  items: [],
  events: [],
  playlists: []
};

const store = createContext(initialState);
const { Provider } = store;

const parseData = obj => {
  let newObj = {};
  delete obj.events;

  for (const key in obj) {
    newObj[key] = [];
    if (obj[key]) for (const kk in obj[key]) {
      newObj[key].push({ key: kk, ...obj[key][kk] })
    }
  }
  newObj.playlists = newObj.playlists.map(playlist => ({ ...playlist, tracks: Object.values(playlist.tracks).reverse() || [] }));

  return newObj;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'ITEMS_UPDATE':
      return { ...state, items: action.payload };
    case 'EVENTS_UPDATE':
      return { ...state, events: action.payload };
    case 'PLAYLISTS_UPDATE':
      return { ...state, events: action.payload };
    case 'STATE_UPDATE':
      return action.payload
    default:
      return state;
  }
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [items, loadingItems, errorItems] = useListVals(database.ref('items'), { keyField: 'key' });
  // const [playlists, loadingPlaylists, errorPlaylists] = useListVals(database.ref('playlists'), { keyField: 'key' });
  const [db, loading, errorDb] = useObjectVal(database.ref('/'), { keyField: 'key' });

  /* useEffect(() => {
   !loadingItems && dispatch({ type: 'ITEMS_UPDATE', payload: items })
   }, [items, loadingItems]);
   */
  useEffect(() => {
    !loading && dispatch({ type: 'STATE_UPDATE', payload: parseData(db) })
  }, [db, loading]);

  return <Provider value={{ state, dispatch, loading }}>{children}</Provider>;
};

export { store, StateProvider }
