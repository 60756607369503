import React from 'react';
import routes from 'helpers/routes';
import { Route, Switch } from 'react-router-dom';

const Router = () => {
  return (
    <Switch>
      {
        routes.map((route, idx) => (route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => <route.component {...props} />}
            />
          )
          : null))
      }
    </Switch>
  );
};

export default Router;
